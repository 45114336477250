<template>
  <AlertConfirmation
    v-if="alert_confirmation"
    @confirmNo="handle_clicked_no"
    @confirmYes="handle_clicked_yes"
    :animation_class="animation_alert_confirmation_class"
    text="Yakin membatalkan transaksi?"
    text_class="text-xl font-medium" />

  <section class="w-screen h-screen absolute flex justify-center items-top">
    <div :class="{'animate__animated animate__zoomIn': showModal, 'animate__animated animate__zoomOut': !showModal}" class="z-50 modal bg-white rounded-lg shadow-lg border-t-4 border-primary-color-2 w-[80%] max-h-[80%]">
      <div class="modal-header border-b border-gray-400 justify-between flex">
        <div class="w-full py-4">
          <h5 class="text-center font-bold text-lg">Detail Agenda</h5>
        </div>
        <PlusIcon @click="closeModal()" class="h-6 w-6 rotate-45 cursor-pointer mt-2 mr-2" />
      </div>
      <div v-if="alert_success || alert_danger" class="px-6">
        <AlertSuccess v-if="alert_success" :text="alert_text"/>
        <AlertDanger v-if="alert_danger" @hideAlertDanger="hide_alert_danger" :text="alert_text"/>
      </div>
      <div class="modal-body px-6 overflow-y-scroll max-h-[450px] mt-3">
        <div class="flex justify-end mb-3">
          <span v-if="detailAgenda?.trx_status == 1" class="text-sm bg-secondary-orange-1 text-secondary-orange-2 px-4 py-1 rounded-lg">
            Pending
          </span>
          <span v-if="detailAgenda?.trx_status == 2" class="text-sm bg-secondary-green-1 text-secondary-green-2 px-4 py-1 rounded-lg">
            Completed
          </span>
          <span v-if="detailAgenda?.trx_status == 3" class="text-sm bg-secondary-red-1 text-secondary-red-2 px-4 py-1 rounded-lg">
            Canceled
          </span>
        </div>
        <div class="profile bg-white border rounded-lg py-3 px-5 shadow-md flex items-center justify-between mb-6">
          <div class="flex items-center">
            <div class="flex items-center profile">
              <div class="logo me-3 rounded-full bg-red-900 w-[50px] h-[50px] flex justify-center items-center overflow-hidden">
                <img class="object-cover w-full h-full" src="/images/jeju_logo.webp" alt="logo jeju">
              </div>
              <div class="flex flex-col">
                <h1 class="font-bold capitalize">{{ detailAgenda?.customer?.name }}</h1>
                <!-- <p class="text-sm font-light">No Telp</p> -->
              </div>
            </div>
          </div>
          <div class="invoice flex">
            <button id="open-modal-invoice" @click="showModalInvoice" class="flex items-center border me-3 border-gray-700 rounded-lg px-3 py-1 text-sm">
              <DocumentTextIcon class="w-4 h-4 me-1 text-gray-700" />
              Invoice
            </button>
            
            <ModalInvoice
              v-if="modalInvoice"
              @closeModalInvoice="hideModalInvoice"
              :trx="detailAgenda"
              />
          </div>
        </div>
        <div v-for="order in detailAgenda.service_items" :key="order.id" class="order-detail border rounded-lg p-6 mb-3" >
          <div class="flex items-center profile mb-4">
            <div class="logo me-3 rounded-full bg-red-900 w-[50px] h-[50px] flex justify-center items-center overflow-hidden">
              <img class="object-cover w-full h-full" src="/images/jeju_logo.webp" alt="logo jeju">
            </div>
            <div class="flex flex-col">
              <h1 class="font-bold text-secondary-red-1 text-sm">{{ order?.product_detail.name }}</h1>
              <p class="text-sm font-light">55 menit</p>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div class="time-duration-staff flex">
              <div class="start-time flex flex-col mx-3">
                <label for="" class="mb-1">Waktu</label>
                <span class="bg-secondary-blue-2 text-secondary-blue-1 px-3 py-1 rounded-md">
                  <p class="pb-1">{{ formatDate(detailAgenda?.appointment_date) }}</p>
                  <hr class="border border-b border-primary-color-3">
                  <p class="pt-1">{{ order.start_service_time }}</p>
                </span>
              </div>
              <div class="staff flex flex-col mx-3">
                <label class="mb-1">Staff</label>
                <div class="w-full">
                  <span v-if="order?.staff_list.length <= 0" class="text-gray-400 italic">
                    ~ Belum ada staff dipilih
                  </span>
                  <span v-for="(staff, index) in order?.staff_list" :key="index" class="bg-secondary-purple-2 text-secondary-purple-1 px-3 mb-2 py-1 rounded-md flex items-center justify-between">
                    {{ staff?.employee_name }}
                  </span>
                </div>
              </div>
            </div>
            <div class="grand-total flex flex-col">
              <p class="text font-medium">Price : </p>
              <span v-if="parseInt(order.discount) !== 0">
                <p class="line-through text-sm">{{ formatRupiah(order.price_per_unit) }}</p>
                <h4 class="font-bold text-xl">{{ formatRupiah(calculateDiscount(order.price_per_unit, order.discount)) }}</h4>
              </span>
              <h4 v-if="parseInt(order.discount) == 0" class="font-bold text-xl">{{ formatRupiah(order.price_per_unit) }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-t border-gray-400 justify-between items-center flex lg:px-6 lg:py-3 md:p-6">
        <div class="flex">
          <button
            @click="cancelTransaction(detailAgenda.id)"
            :disabled="detailAgenda?.trx_status == 2 || detailAgenda?.trx_status == 3 ? true : false"
            :class="detailAgenda?.trx_status == 2 || detailAgenda?.trx_status == 3 ? 'bg-gray-200 text-gray-400' : 'text-secondary-red-1 border border-secondary-red-1'"
            class="rounded-lg py-1 px-3 text-sm me-2">Batalkan Transaksi</button>
          <button
            @click="editTransaction(detailAgenda.id)"
            :disabled="detailAgenda?.trx_status == 2 || detailAgenda?.trx_status == 3 ? true : false"
            :class="detailAgenda?.trx_status == 2 || detailAgenda?.trx_status == 3 ? 'bg-gray-200 text-gray-400' : 'text-secondary-green-1 border border-secondary-green-1'"
            class="rounded-lg py-1 px-3 text-sm">Edit Transaksi</button>
        </div>
        <div class="checkout">
          <SubmitButton @click="closeModal()" button_name="Close" button_class="bg-gray-200 rounded-lg me-3 border border-gray-400"/>
          <SubmitButton
            @click="checkout(detailAgenda?.id)"
            :is_disable="detailAgenda?.trx_status == 2 || detailAgenda?.trx_status == 3 ? true : false"
            button_name="Checkout"
            :button_class="detailAgenda?.trx_status == 2 || detailAgenda?.trx_status == 3 ? 'bg-gray-200 text-gray-400' : 'bg-primary-color-2 text-white'"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ModalInvoice from './ModalInvoice.vue';
import SubmitButton from '../form/SubmitButton.vue';
import AlertDanger from '@/components/alerts/AlertDanger.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
import AlertConfirmation from '@/components/alerts/AlertConfirmation.vue';

import { mapGetters, mapActions } from 'vuex';
import { formatRupiah, formatDate } from '@/helpers/formating';
import { calculateDiscount } from '@/helpers/calculation';
import { PlusIcon, DocumentTextIcon } from '@heroicons/vue/24/solid';

export default {
  name: "DetailAgendaView",
  emits: ['closeModalDetailAgenda'],
  props: {
    detailAgenda: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      modalInvoice: false,
      showModal: true,
      selected_staff: [],
      selected_staff_id: '',
      transaction: this.detailAgenda,

      alert_success: false,
      alert_danger: false,
      alert_text: '',
      alert_confirmation: false,
      animation_alert_confirmation_class: '',
    }
  },
  components: {
    ModalInvoice, SubmitButton, AlertConfirmation, AlertSuccess, AlertDanger,
    DocumentTextIcon, PlusIcon
  },
  methods: {
    formatRupiah,
    formatDate,
    calculateDiscount,
    ...mapActions('transactions', ['updateTransaction']),

    closeModal() {
      this.showModal = false;
      this.$emit('closeModalDetailAgenda');
    },
    showModalInvoice() {
      this.modalInvoice = true;
    },
    hideModalInvoice() {
      setTimeout(() => {
        this.modalInvoice = false;
      }, 500);
    },

    // Confirmation Cancel Transaction
    cancelTransaction(transaction_id) {
      console.log('id trx: ', transaction_id);
      this.showAlertConfirmation();
    },
    showAlertConfirmation() {
      this.alert_confirmation = true;
      this.animation_alert_confirmation_class = 'animate__backInDown';
    },
    handle_clicked_no(){
      // this.isBlur = false;
      this.alert_confirmation = true;
      this.animation_alert_confirmation_class =  'animate__backOutUp';
    },
    async handle_clicked_yes(){
      if(this.detailAgenda.trx_status == 3) {
        this.alert_danger = "Transaksi sudah selesai, tidak bisa dibatalkan!";
        return;
      }
      this.transaction.trx_status = 3;
      await this.updateTransaction(this.transaction);
      this.closeModal();
    },

    // Edit Transaction
    editTransaction(transaction_id) {
      this.$router.push({ name: 'EditAppointment', params: { transaction_id: transaction_id } });
    },

    checkout(detailAgendaId) {
      this.$router.push({ name: 'CheckoutView', params: { id: detailAgendaId } });
    }
  },
  computed: {
    ...mapGetters('employees', ['allEmployees']),
    availableEmployees() {
      return this.allEmployees.filter(employee => !this.selected_staff.find(selected => selected.id === employee.id));
    }
  },
  created() {
    this.$store.dispatch('employees/fetchAllEmployees');
    // console.log('detail agenda: ', this.detailAgenda);
  },
}
</script>