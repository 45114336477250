<template>
  <div class="mb-6 flex items-center justify-between">
    <div class="flex items-center">
      <span><InputForm @change="fetchInvoices(1)" v-model="filter.start_date" input_class="w-40 me-2" type="date"/></span>
      <MinusIcon class="w-5 h-5"/>
      <span><InputForm @change="fetchInvoices(1)" v-model="filter.end_date" input_class="w-40 ms-2" type="date"/></span>
    </div>
    <div class="flex items-center">
      <button
        @click="setPaymentStatus('')"
        :class="paymentAllStatusClass('')"
        class="px-3 py-1 text-sm rounded-lg border">All</button>
      <button
        @click="setPaymentStatus(1)"
        :class="paymentPendingStatusClass()"
        class="mx-3 px-3 py-1 text-sm rounded-lg border">Pending</button>
      <button
        @click="setPaymentStatus(2)"
        :class="paymentCompletedStatusClass()"
        class="px-3 py-1 text-sm rounded-lg border">Completed</button>
    </div>
  </div>
  <div class="overflow-x-scroll">
    <table class="table-auto w-full">
      <thead class="bg-gray-300">
        <tr class="border">
          <th class="p-3 border-x text-center">No</th>
          <th class="p-3 border-x text-left">Invoice ID</th>
          <th class="p-3 border-x text-left">Invoice Date</th>
          <th class="p-3 border-x text-left">Due Date</th>
          <th class="p-3 border-x text-left">Payment Date</th>
          <th class="p-3 border-x text-left">Total Transaction</th>
          <th class="p-3 border-x text-left">Total Payment</th>
          <th class="p-3 border-x text-center">Status</th>
          <th class="p-3 border-x text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="allInvoices == 0">
          <td colspan="9" class="text-gray-400 italic text-lg text-center py-3"> ~ Tidak ada data ~ </td>
        </tr>
        <tr v-for="(invoice, index) in allInvoices" :key="invoice.id" class="border-y">
          <td class="p-3 text-center">{{ index + 1 }}</td>
          <td class="p-3">{{ invoice.invoice_code }}</td>
          <td class="p-3">{{ formatDate(invoice.invoice_date) }}</td>
          <td class="p-3">{{ formatDate(invoice.due_date) }}</td>
          <td class="p-3">{{ invoice.payment_date ? formatTime(invoice.payment_date) : '' }}</td>
          <td class="p-3">{{ formatRupiah(invoice.total_transaction) }}</td>
          <td class="p-3">{{ formatRupiah(invoice.total_payment) }}</td>
          <td class="p-3 text-center">
            <BadgeSuccess v-if="invoice.payment_status == 2" text="Completed"/>
            <BadgeWarning v-if="invoice.payment_status == 1" text="Pending"/>
          </td>
          <td class="p-3 text-center flex flex-wrap justify-around">
            <button @click="showInvoice(invoice.id)" class="bg-gray-300 p-2 rounded-lg">
              <EyeIcon class="h-4 w-4"/>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <PaginationComponent
    :totalItems="pagination.totalItems"
    :perPage="parseInt(pagination.perPage)"
    :currentPage="parseInt(pagination.page)"
    :previousPageLink="pagination?.previousPageLink"
    :nextPageLink="pagination?.nextPageLink"
    @page-changed="fetchInvoices"
  />
</template>

<script>
import InputForm from '@/components/form/InputForm.vue';
import BadgeSuccess from '@/components/badge/BadgeSuccess.vue';
import BadgeWarning from '@/components/badge/BadgeWarning.vue';
import PaginationComponent from '@/components/PaginationComponent.vue';
import { formatTime, formatDate, formatRupiah } from '@/helpers/formating';
import { mapGetters, mapActions } from 'vuex';
import { MinusIcon, EyeIcon } from '@heroicons/vue/24/solid';

export default {
  name: "SalesTabInvoice",
  components: {
    BadgeSuccess, BadgeWarning, PaginationComponent, InputForm,
    MinusIcon, EyeIcon
  },
  data() {
    return {
      filter: {
        start_date: new Date().toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0],
        payment_status: ''
      }
    }
  },
  computed: {
    ...mapGetters('invoices', ['allInvoices', 'pagination']),
  },
  created() {
    this.fetchInvoices(this.pagination.page || 1);
  },
  methods: {
    formatTime, formatDate, formatRupiah,
    ...mapActions('invoices', ['fetchInvoices']),

    async fetchInvoices(page = 1, limit = 10) {
      const params = {
        page,
        limit,
        search: '',
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
        payment_status: this.filter.payment_status
      };
      await this.$store.dispatch('invoices/fetchInvoices', params);
    },

    // Invoice Statuses
    setPaymentStatus(status) {
      this.filter.payment_status = status;
      this.fetchInvoices(this.pagination.page || 1);
    },
    paymentAllStatusClass() {
      return this.filter.payment_status == '' ? 'bg-gray-500 text-white' : 'border-gray-600';
    },
    paymentPendingStatusClass(){
      return this.filter.payment_status == 1 ? 'text-secondary-red-2 bg-secondary-red-1' : 'text-secondary-red-1 border-secondary-red-1';
    },
    paymentCompletedStatusClass(){
      return this.filter.payment_status == 2 ? 'text-secondary-green-2 bg-secondary-green-1' : 'text-secondary-green-1 border-secondary-green-1';
    },

    // Detail Invoice
    showInvoice(invoice_id) {
      this.$router.push({ name: 'DetailInvoice', params: { id: invoice_id } });
    }
  },
}
</script>