<template>
   <section class="bg-white border border-b-2 px-6 py-4 flex justify-between items-center w-full z-30 h-[70px] fixed">
      <BackButton :to_url="back_url"/>
      <div class="w-full">
         <h1 class="text-center font-bold text-lg">{{ pageTitle }}</h1>
      </div>
   </section>
</template>

<script>
import BackButton from './buttons/BackButton.vue';
export default {
   name: 'TopBar',
   components: {
      BackButton
   },
   props: {
      back_url: [Object, String],
      pageTitle: String,
   }
}
</script>