<template>
  <button
    :id="id"
    :class="button_class"
    :disabled="is_disable"
    type="submit"
    class="py-2 px-4 rounded-lg">{{ button_name }}</button>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    id: String,
    button_class: String,
    button_name: String,
    is_disable: Boolean
  }
}
</script>