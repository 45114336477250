<template>
  <span class="text-sm bg-secondary-blue-2 text-secondary-blue-1 px-3 py-1 rounded-lg" :class="badge_class">{{ text }}</span>
</template>

<script>
export default {
  name: "BadgePrimary",
  props: {
    text: String,
    badge_class: String
  }
}
</script>