<template>
  <span class="text-sm bg-secondary-green-2 text-secondary-green-1 px-3 py-1 rounded-lg" :class="badge_class">{{ text }}</span>
</template>

<script>
export default {
  name: "BadgeSuccess",
  props: {
    text: String,
    badge_class: String
  }
}
</script>