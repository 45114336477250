<template>
  <Topbar2 pageTitle="Create New Appoinment" :back_url="{ name: 'CalendarView' }"/>
  <div
    @click="hideModal()"
    id="overlay"
    class="fixed inset-0 bg-black bg-opacity-30 z-40"
    :class="{ 'hidden': !isBlur }"
  ></div>
  <ModalProduct
    ref="modal"
    v-if="modalProduct"
    @closeModalProduct="hideModalProduct()"
    @serviceItem="handleSelectedServiceItem"
    :product="selectedProduct"
  />
  <ModalAddCustomer
    ref="modal"
    v-if="modalAddCustomer"
    @closeModalAddCustomer="hideModalAddCustomer()"
    @openModalAllCustomer="showModalAllCustomer()"
  />
  <ModalAllCustomer
    ref="modal"
    v-if="modalAllCustomer"
    @openModalAddCustomer="showModalAddCustomer()"
    @closeModalAllCustomer="hideModalAllCustomer()"
    @selectedCustomer="handleSelectedCustomer"
  />
  <section class="p-6 mt-[70px] h-[90vh]">
    <div class="bg-white rounded-lg py-6 px-5 shadow-lg h-full">
      <AlertSuccess v-if="alert_success" :text="alert_text"/>

      <div class="flex h-full">
        <div class="w-[60%] md:w-1/2 h-full overflow-y-hidden me-2 px-1">
          <div class="flex justify-between items-end mb-6">
            <span class="w-1/2 me-3">
              <InputForm v-model="transaction.appointment_date" label_class="text-primary-color-2" label="Pilih Tanggal" type="date" input_class="w-full border-primary-color-2 shadow-md"/>
            </span>
            <div class="w-1/2">
              <button @click="showModalAllCustomer()" class="bg-primary-color-1 py-2 w-full hover:bg-primary-color-2 text-white shadow-md border border-gray-400 rounded-lg flex justify-center items-center">
              <UserGroupIcon class="w-5 h-5 me-3"/>
              Pilih Pelanggan
            </button>
            </div>
          </div>

          <InputForm v-model="searchQuery" @input="handleSearch" placeholder="Cari nama produk ..." input_class="w-full mb-3 "/>

          <div class="categories flex mb-3 overflow-x-scroll">
            <button
              @click="selectCategory(null)"
              :class="selectedCategory === null ? 'bg-secondary-red-1 text-secondary-red-2' : 'bg-secondary-red-2 text-secondary-red-1'"
              class="rounded-md px-4 py-1 m-2">All</button>
            <button
              v-for="category in allCategories" :key="category.id"
              @click="selectCategory(category)"
              :class="category.id === selectedCategory?.id ? 'bg-secondary-red-1 text-secondary-red-2' : 'bg-secondary-red-2 text-secondary-red-1'"
              class="rounded-md px-4 py-1 m-2">{{ category.name }}</button>
          </div>
          <div class="products overflow-y-scroll h-full pb-60 relative">
            <div class="w-full flex flex-wrap">
              <div
                v-for="product in productsByCategory"
                :key="product.id"
                @click="showModalProduct(product)"
                class="card border cursor-pointer border-gray-400 flex rounded-lg bg-white overflow-hidden m-2 md:w-full lg:w-full xl:w-[400px]">
                <div class="w-[100px] md:w-[60px] overflow-hidden">
                  <img class="object-cover w-full h-full" src="/images/jeju_logo.webp" alt="">
                </div>
                <div class="py-2 px-4">
                  <h5 class="text-secondary-red-1 font-bold text-lg mb-1">{{ product.name }}</h5>
                  <div class="flex text-gray-400">
                    <span v-if="parseInt(product.discount) !== 0">
                      <p class="line-through text-sm">{{ formatRupiah(product.sale_price) }}</p>
                      <h4 class="font-bold text-black">{{ formatRupiah(calculateDiscount(product.sale_price, product.discount)) }}</h4>
                    </span>
                    <h4 v-if="parseInt(product.discount) == 0" class="font-bold text-black">{{ formatRupiah(product.sale_price) }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- REKAP ORDER -->
        <div class="w-[40%] md:w-1/2 max-h-full overflow-hidden ms-2">
          <div class="bg-gray-100 p-6 rounded-lg h-full overflow-y-scroll">            
            <div>
              <AlertDanger v-if="alert_danger" :text="alert_text" @closeAlertDanger="hideAlertDanger()"/>
              <div class="bg-white rounded-lg px-6 py-3 mb-6">
                <p class="font-light text-sm">Nama Pelanggan :</p>
                <template v-if="transaction_id">
                  <h5 class="font-bold text-xl capitalize">{{ transaction?.customer?.name }}</h5>
                </template>
                <template v-else>
                  <h5 v-if="customer.name" class="font-bold text-xl capitalize">{{ customer.name }}</h5>
                  <h5 v-else class="italic text-gray-400">~ Silahkan pilih pelanggan!</h5>
                </template>
              </div>

              <template v-if="transaction_id">
                <div v-for="service_item in transaction?.service_items" :key="service_item" class="selected-product mb-3 bg-white rounded-lg h-full overflow-y-scroll">
                  <div class="product-header flex p-2 justify-end">
                    <PlusIcon @click="removeSelectedproduct(service_item)" class="text-red-700 w-6 h-6 rotate-45 cursor-pointer"/>
                  </div>
                  <div class="product-body px-6 pb-6">
                    <div class="mb-3">
                      <h5 class="text-secondary-red-1 font-bold text-lg mb-1">
                        {{ productName(service_item.product_id) }}
                      </h5>
                      <div class="flex text-gray-500">
                        <p class="me-3 font-bold text-primary-color-2">{{ formatRupiah(service_item?.subtotal) }}</p>
                      </div>
                    </div>
                    <div>
                      <table class="table-auto w-full">
                        <thead>
                          <tr>
                            <th class="text-start border-b mb-3">Tanggal</th>
                            <th class="text-start border-b mb-3">Waktu</th>
                            <th class="text-start border-b mb-3">Staff</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> {{ formatDate(transaction.appointment_date) ? formatDate(transaction.appointment_date)  : '~'}} </td>
                            <td> {{ service_item?.start_service_time }}</td>
                            <td class="w-1/2">
                              <div class="flex flex-wrap">
                                <span v-for="staff in service_item.staff_list" :key="staff.user_id" class="bg-secondary-purple-2 text-secondary-purple-1 px-3 py-1 rounded-lg text-sm m-1 items-center">
                                  {{ staff.employee_name }}
                                  <!-- <TrashIcon class="w-4 h-4 text-secondary-red-1 ms-2"/> -->
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </template>

              <template v-else>
                <div v-for="service_item in transaction.services" :key="service_item" class="selected-product mb-3 bg-white rounded-lg h-full overflow-y-scroll">
                  <div class="product-header flex p-2 justify-end">
                    <PlusIcon @click="removeSelectedproduct(service_item)" class="text-red-700 w-6 h-6 rotate-45 cursor-pointer"/>
                  </div>
                  <div class="product-body px-6 pb-6">
                    <div class="mb-3">
                      <h5 class="text-secondary-red-1 font-bold text-lg mb-1">
                        {{ productName(service_item.product_id) }}
                      </h5>
                      <div class="flex text-gray-500">
                        <p class="me-3 font-bold text-primary-color-2">{{ formatRupiah(service_item?.subtotal) }}</p>
                      </div>
                    </div>
                    <div>
                      <table class="table-auto w-full">
                        <thead>
                          <tr>
                            <th class="text-start border-b mb-3">Tanggal</th>
                            <th class="text-start border-b mb-3">Waktu</th>
                            <th class="text-start border-b mb-3">Staff</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> {{ service_item.order_date ? service_item.order_date  : '~'}} </td>
                            <td> {{ service_item?.start_service_time }}</td>
                            <td class="w-1/2">
                              <div class="flex flex-wrap">
                                <span v-for="staff in service_item.staffs" :key="staff.user_id" class="bg-secondary-purple-2 text-secondary-purple-1 px-3 py-1 rounded-lg text-sm m-1 items-center">
                                  {{ staff.employee_name }}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
              
            </div>
            <div class="mt-14 justify-between">
              <div class="mb-6 flex justify-between text-secondary-blue-1 font-bold text-2xl">
                <span>Subtotal</span>
                <span>{{ formatRupiah(transaction?.subtotal) }}</span>
              </div>
              <div class="grid grid-cols-2 gap-4">
                <div class="col-span-1">
                  <button :disabled="transaction_id" @click="saveOrder()" class="bg-primary-color-2 text-white w-full py-2 rounded-lg">Simpan Order</button>
                </div>
                <div class="col-span-1">
                  <button :disabled="transaction_id" @click="checkout()" class="bg-secondary-red-1 text-secondary-red-2 w-full py-2 rounded-lg">Checkout</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Topbar2 from '@/components/Topbar2.vue';
import InputForm from '@/components/form/InputForm.vue';
import ModalProduct from '@/components/modals/ModalProduct.vue';
import ModalAddCustomer from '@/components/modals/ModalAddCustomer.vue';
import ModalAllCustomer from '@/components/modals/ModalAllCustomer.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
import AlertDanger from '@/components/alerts/AlertDanger.vue';
import { calculateDiscount } from '@/helpers/calculation';
import { mapGetters, mapActions } from 'vuex';
import { formatRupiah, formatDate } from '@/helpers/formating';
import { UserGroupIcon, PlusIcon  } from '@heroicons/vue/24/solid';
import { debounce } from 'lodash';

export default {
  name: "AppointmentView",
  components: {
    Topbar2, InputForm, ModalProduct, ModalAllCustomer, ModalAddCustomer, AlertSuccess, AlertDanger,
    UserGroupIcon, PlusIcon
  },
  props: {
    selectedCustomer: Object,
    serviceItem: Object,
    props_customer_id: String,
    transaction_id: String
  }, 
  data() {
    return {
      isBlur: false,
      modalProduct: false,
      modalAllCustomer: false,
      modalAddCustomer: false,
      selectedProduct: null,
      selectedCategory: null,
      alert_success: false,
      alert_danger: false,
      alert_text: '',

      customer: {
        id: null,
        name: ''
      },
      transaction: {
        customer_id: null,
        outlet_id: 1,
        transaction_note: '',
        discount: 0,
        discount_note:'',
        tax: 0,
        service_charge: 0,
        customer_location: '',
        appointment_date: new Date().toISOString().split('T')[0],
        appointment_hour: '',
        subtotal: 0,
        grand_total: 0,
        services: []
      },
      searchQuery: ''
    }
  },
  methods: {
    formatDate,
    formatRupiah,
    calculateDiscount,
    ...mapActions('customers', ['fetchCustomerById']),
    ...mapActions('transactions', ['createTransaction', 'fetchTransactionByCustomerId', 'fetchTransactionById']),
    ...mapActions('products', ['deleteProductById', 'fetchProducts']),

    async fetchProducts(page = 1, limit = 10) {
      const params = {
        page,
        limit,
        product_search: this.searchQuery || '',
      };
      await this.$store.dispatch('products/fetchProducts', params);
    },
    handleSearch: debounce(function () {
      this.selectCategory(null);
      console.log('search query: ', this.searchQuery); 
      this.fetchProducts(1); 
    }, 300),

    hideModalAllCustomer() {
      setTimeout(()=> {
        this.modalAllCustomer = false;
      }, 500);
      this.isBlur = false;
    },
    showModalProduct(product) {
      this.isBlur = true;
      this.modalProduct = true;
      this.selectedProduct = product;
    },
    hideModalProduct() {
      setTimeout(()=> {
        this.modalProduct = false;
      }, 500);
      this.isBlur = false;
    },
    selectCategory(category) {
      this.selectedCategory = category;
    },
    showModalAddCustomer() {
      this.isBlur = true;
      this.modalAddCustomer = true;
    },
    hideModalAddCustomer() {
      this.isBlur = false;
      this.modalAddCustomer = false;
    },
    showModalAllCustomer() {
      this.modalAllCustomer = true;
      this.isBlur = true;
    },
    showAlertSuccess(){
      this.alert_success = true;
      this.alert_text = 'Pelanggan berhasil ditambahkan';
      setTimeout(()=>{
        this.alert_success = false;
        this.alert_text = '';
      }, 2000);
    },
    handleSelectedCustomer(customer) {
      this.transaction.customer_id = customer.id;
      this.customer.id = customer.id;
      this.customer.name = customer.name;
      console.log('trans updated, selected customer: ', this.transaction);
    },
    handleSelectedServiceItem(service_item) {
      // this.transaction.appointment_date = service_item.order_date;
      if(this.transaction_id) {
        console.log();
        service_item.order_date = this.transaction.appointment_date;
        this.transaction.appointment_hour = service_item.start_service_time;
        this.transaction?.service_items.push(service_item);
      }
      else {
        service_item.order_date = this.transaction.appointment_date;
        this.transaction.appointment_hour = service_item.start_service_time;
        this.transaction?.services.push(service_item);
      }
      this.calculateSubtotal();
      console.log('trans updated, selected product: ', this.transaction);
    },
    removeSelectedproduct(selected_product) {
      console.log('remove this product: ', selected_product);

      if(this.transaction_id) {
        const index = this.transaction?.service_items.findIndex(service_item => service_item.product_id === selected_product.product_id);
        if(index !== -1 ) {
          this.transaction?.service_items.splice(index, 1);
        }
      }
      else {
        const index = this.transaction?.services.findIndex(service_item => service_item.product_id === selected_product.product_id);
        if(index !== -1 ) {
          this.transaction?.services.splice(index, 1);
        }
      }
      this.calculateSubtotal();
    },
    productName(product_id) {
      const product = this.allProducts.find(product => product.id === product_id);
      return product ? product.name : '~ Produk tidak ditemukan';
    },
    calculateSubtotal() {
      // Menghitung total dari semua service_items (EDIT TRANSAKSI)
      if(this.transaction_id) {
        this.transaction.subtotal = this.transaction.service_items.reduce((total, service_item) => {
          return total + service_item.subtotal;
        }, 0);
      }
      else { // Menghitung total dari semua services (NEW APPOINTMENT)
        this.transaction.subtotal = this.transaction?.services.reduce((total, service_item) => {
          return total + service_item.subtotal;
        }, 0); // Mulai dari 0
      }
    },
    async checkout() {
      if(!this.transaction.customer_id) {
        this.alert_danger = true;
        this.alert_text = 'Pilih Customer terlebih dulu!';
        return;
      }
      // if(this.transaction.services.length <= 0) {
      //   this.alert_danger = true;
      //   this.alert_text = 'Pilih Produk terlebih dulu!';
      //   return;
      // }
      try {
        console.log('to checkout: ', this.transaction);
        const createdTransaction = await this.createTransaction(this.transaction);
        this.$router.push({ name: 'CheckoutView', params: { id: createdTransaction.data } });
      }
      catch(error) {
        console.error(error)
        this.alert_text = 'Gagal membuat transaksi: ', error;
        return;
      }
    },
    async saveOrder() {
      if(!this.transaction.customer_id) {
        this.alert_danger = true;
        this.alert_text = 'Pilih Customer terlebih dulu!';
        return;
      }
      try {
        console.log('order berhasil disimpan: ', this.transaction);
        await this.createTransaction(this.transaction);
        this.alert_success = true;
        this.alert_text = 'Order berhasil disimpan!';
      }
      catch(error) {
        console.error(error)
        this.alert_text = 'Gagal membuat order: ', error;
        return;
      }
    },
    hideAlertDanger() {
      this.alert_danger = false;
      this.alert_text = '';
    },

    // Else for Closing Modal 
    hideModal() {
      this.$refs.modal.closeModal();
    }
  },
  computed: {
    ...mapGetters('products', ['allProducts']),
    ...mapGetters('categories', ['allCategories']),

    productsByCategory() {
      if(this.selectedCategory === null ) {
        return this.allProducts.filter(product => product.saleable === true);
      }

      return this.allProducts.filter( product =>
        product?.category_id === this.selectedCategory.id &&
        product.saleable === true
      );
    },
  },
  async created() {
    this.$store.dispatch('products/fetchProducts');
    this.$store.dispatch('categories/fetchCategories');
    
    if(this.props_customer_id) {
      this.transaction.customer_id = this.props_customer_id;
      this.customer.id = this.props_customer_id;
      const response = await this.fetchCustomerById(this.props_customer_id);
      const customerData = response.data;
      this.customer.name = customerData.name;
      return;
    }

    if(this.transaction_id) {
      this.transaction = null
      console.log('this transaction; ', this.transaction);
      const response = await this.fetchTransactionById(this.transaction_id);
      this.transaction = response.data;
      console.log('then transaction; ', this.transaction);
    }
  },
}
</script>