<template>
  <Topbar2 :pageTitle="isEdit ? 'Edit Produk' : 'Tambah Produk'" back_url="/produk"/>
  <section class="p-6 px-40 xl:px-56 md:px-20 mt-[100px]">
    <AlertSuccess v-if="alert_success" :text="alert_text"/>
    <AlertDanger v-if="alert_danger" @closeAlertDanger="hideAlertDanger()" :text="alert_text"/>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-6">
        <InputForm id="product-name" v-model="product.name" input_class="w-full mb-5" label="Nama" placeholder="Nama produk" label_class="mb-1 block text-start" type="text"/>        
        <div class="relative mb-5">
          <label for="" class="mb-1 block text-start font-medium">Durasi</label>
          <input v-model="product.duration" placeholder="Cth: 50" class="block text-lg mt-1 flex-1 border border-gray-400 rounded-lg px-3 py-1 text-gray-500 placeholder:text-gray-400 placeholder:text-[12px] sm:text-sm sm:leading-6 focus:outline-none focus:ring focus:ring-gray-500 w-full" type="text">
          <span class="absolute bottom-0 right-0 py-1 px-3 border font-medium border-gray-400 rounded-r-lg bg-gray-200 text-gray-400">
            Menit
          </span>
        </div>
        <div class="mb-5">
          <label class="font-medium" for="">Kategori</label>
          <select id="category" v-model="product.category_id" class="block mt-1 flex-1 border border-gray-400 rounded-md px-3 py-2 text-gray-500 sm:text-sm sm:leading-6 focus:outline-none focus:ring focus:ring-gray-500 w-full">
            <option value="">Pilih kategori</option>
            <option v-for="category in allCategories" :key="category.id" :value="category.id">{{ category.name }}</option>
          </select>
        </div>
        <InputForm id="product-price" v-model="product.sale_price" input_class="w-full mb-5" label="Harga" placeholder="Harga produk" label_class="mb-1 block text-start" type="number"/>
        <div class="relative mb-5">
          <label for="" class="mb-1 block text-start font-medium">Diskon</label>
          <input v-model="product.discount" placeholder="Cth: 50" class="block text-lg mt-1 flex-1 border border-gray-400 rounded-lg px-3 py-1 text-gray-500 placeholder:text-gray-400 placeholder:text-[12px] sm:text-sm sm:leading-6 focus:outline-none focus:ring focus:ring-gray-500 w-full" type="number">
          <span class="absolute bottom-0 right-0 py-1 px-3 border font-medium border-gray-400 rounded-r-lg bg-gray-200 text-gray-400">
            %
          </span>
        </div>
        <InputForm :is_disabled="true" v-model="special_price" input_class="w-full mb-5" label="Harga Setelah Diskon" label_class="mb-1 block text-start" type="text"/>
        <SwitchGroup as="div" class="flex gap-x-4 sm:col-span-2">
          <SwitchLabel class="text-sm leading-6 text-gray-600">
            <span class="font-semibold">Produk Aktif ?</span>
          </SwitchLabel>
          <div class="flex h-6 items-center">
            <Switch v-model="product.saleable" :class="[product.saleable ? 'bg-primary-color-2' : 'bg-gray-200', 'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
              <span aria-hidden="true" :class="[product.saleable ? 'translate-x-3.5' : 'translate-x-0', 'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out']" />
            </Switch>
          </div>
        </SwitchGroup>
      </div>
      <div class="col-span-6">
        <TextArea v-model="product.desc" textarea_class="mb-5" id="product-description" rows="4" label="Deskripsi (optional)" />
        <div class="mb-5">
          <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Foto</label>
          <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900 bg-white py-5">
            <div class="text-center">
              <PhotoIcon class="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
              <div class="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                <label for="file-upload" class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                  <span>Upload a file</span>
                  <input id="file-upload" name="file-upload" type="file" class="sr-only" disabled/>
                </label>
              </div>
              <p class="text-xs leading-5 text-gray-600">PNG, JPG max size 5MB</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-10">
      <div class="col-span-6">
      </div>
      <div class="col-span-6 flex justify-end">
        <CancelButton class="mb-10 me-3" to_url="/produk" />
        <span><SubmitButton @click="submit()" button_name="Simpan" class="bg-primary-color-2 text-white"/></span>
      </div>
    </div>
  </section>
</template>

<script>
import Topbar2 from '@/components/Topbar2.vue';
import InputForm from '@/components/form/InputForm.vue';
import TextArea from '@/components/form/TextArea.vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import SubmitButton from '@/components/form/SubmitButton.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
import AlertDanger from '@/components/alerts/AlertDanger.vue';
import { PhotoIcon  } from '@heroicons/vue/24/solid';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "AddEditProduct",
  props: ['id'],
  components: {
    Topbar2, InputForm, TextArea, CancelButton, SubmitButton, Switch, SwitchGroup, SwitchLabel, AlertSuccess, AlertDanger,
    PhotoIcon
  },
  data() {
    return {
      alert_success: false,
      alert_danger: false,
      alert_text: '',

      product: {
        id: null,
        name: '',
        duration: null,
        category_id: null,
        sale_price: null,
        discount: 0,
        desc: '',
        saleable: true,
      },
      special_price: 0,
      isEdit: false
    }
  },
  methods: {
    ...mapActions('products', ['createProduct', 'fetchProductById', 'updateProduct']),

    calculateSpecialPrice() {
      if (this.product.sale_price && this.product.discount) {
        const discountFactor = 1 - (this.product.discount / 100);
        this.special_price = this.product.sale_price * discountFactor;
      }
      else {
        this.special_price = 0;
      }
    },
    
    async submit() {
      if(!this.product.discount) {
        this.product.discount = 0;
      }
      if(!this.product.name) {
        this.alert_danger = true;
        this.alert_text = 'Field Nama harus diisi!';
        return;
      }
      if(!this.product.sale_price) {
        this.alert_danger = true;
        this.alert_text = 'Field Harga harus diisi!';
        return;
      }
      if(this.isEdit) {
        try {
          await this.updateProduct(this.product);
          this.alert_success = true;
          this.alert_text = 'Berhasil ubah data produk';
          setTimeout(() => {
            this.alert_success = false;
            this.$router.push('/produk');
          }, 500);
        }
        catch (error) {
          this.alert_danger = true;
          this.alert_text = 'Gagal menambah produk baru: ' + error;
          console.error('Gagal update produk', error);
        }
      }
      else {
        try {
          await this.createProduct(this.product);
          this.alert_success = true;
          this.alert_text = 'Berhasil menambah produk';
          setTimeout(() => {
            this.alert_success = false;
            this.$router.push('/produk');
          }, 500);
        }
        catch (error) {
          this.alert_danger = true;
          this.alert_text = 'Gagal menambah produk: ' + error
          console.error('Gagal menambah produk', error);
        }
      }
    },
    hideAlertDanger() {
      this.alert_danger = false;
      this.alert_text = '';
    },
  },
  computed: {
    ...mapGetters('categories', ['allCategories']),
  },
  watch: {
    'product.sale_price': 'calculateSpecialPrice',
    'product.discount': 'calculateSpecialPrice',
  },
  async created(){
    this.$store.dispatch('categories/fetchCategories');

    const productId = this.id;
    console.log("produk id: ", productId);
    if (productId) {
      this.isEdit = true;

      try {
        const response = await this.fetchProductById(productId);
        const productData = response.data;

        this.product = {
          id: productData.id,
          name: productData.name,
          duration: productData.duration,
          sale_price: parseInt(productData.sale_price),
          discount: parseInt(productData.discount),
          category_id: productData.category_id || '',
          desc: productData.desc,
          saleable: productData.saleable,
        };
        this.calculateSpecialPrice();
      }
      catch (error) {
        console.error('Failed to fetch product', error);
      }
    }
  }
}
</script>