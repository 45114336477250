import { createRouter, createWebHistory } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import IndexProfileView from "@/views/pages/profile/ProfileView.vue";
import DashboardView from "@/views/pages/DashboardView.vue";
// import OrderListView from "@/views/pages/OrderListView.vue";
// import AttendanceView from "@/views/pages/attendance/AttendanceView.vue";
// import AttendanceDetailView from "@/views/pages/attendance/AttendanceDetailView.vue";
import EmployeeView from "@/views/pages/employee/EmployeeView.vue";
// import CommissionView from "@/views/pages/employee/CommissionView.vue";
import AddEditEmployee from "@/views/pages/employee/AddEditEmployee.vue";
import CustomerView from "@/views/pages/customer/CustomerView.vue";
import AddCustomer from "@/views/pages/customer/AddCustomer.vue";
import EditCustomer from "@/views/pages/customer/EditCustomer.vue";
// import OutletView from "@/views/pages/outlet/OutletView.vue";
// import AddEditOutlet from "@/views/pages/outlet/AddEditOutlet.vue";
import ProductManagementView from "@/views/pages/product/ProductManagementView.vue";
import AddEditProduct from "@/views/pages/product/AddEditProduct.vue";
// import InventoryView from "@/views/pages/inventory/InventoryView.vue";
// import AddEditInventory from "@/views/pages/inventory/AddEditInventory.vue";
// import AttributeView from "@/views/pages/attribute/AttributeView.vue";
// import AttributeListView from "@/views/pages/attribute/AttributeListView.vue";
import CategoryView from "@/views/pages/category/CategoryView.vue";
import PaymentMethodView from "@/views/pages/setting/PaymentMethodView.vue";
// import TransactionView from "@/views/pages/transaction/TransactionView.vue";
import CheckoutView from "@/views/pages/transaction/CheckoutView.vue";
import SalesMainView from "@/views/pages/sales/SalesMainView.vue";
import DetailInvoice from "@/views/pages/sales/DetailInvoice.vue";
// import CalendarView from "@/views/pages/calendar/CalendarView.vue";
import CalendarView_v2 from "@/views/pages/calendar/CalendarView_v2.vue";
import AppointmentView from "@/views/pages/transaction/AppointmentView.vue"
import ReportView from "@/views/pages/report/ReportMainView.vue"

import { requireAuth } from '@/middleware/auth';
// import { requireSuperadmin } from '@/middleware/role';

const routes = 
[
    {
        path:'/',
        name: 'LoginView',
        component: LoginView,
    },
    {
        path:'/dashboard',
        name:'Dashboard',
        component: DashboardView,
        // beforeEnter: requireAuth
    },
    {
        path:'/pelanggan',
        name: 'CustomerView',
        component: CustomerView,
        beforeEnter: requireAuth
    },
    {
        path:'/pelanggan/tambah-pelanggan',
        name: 'AddCustomer',
        component: AddCustomer,
        beforeEnter: requireAuth
    },
    {
        path:'/pelanggan/detail-pelanggan/:id',
        name: 'EditCustomer',
        props:true,
        component: EditCustomer,
        beforeEnter: requireAuth
    },
    {
        path:'/staff',
        name: 'EmployeeView',
        component: EmployeeView,
        beforeEnter: requireAuth
    },
    {
        path:'/staff/tambah-staff',
        name: 'AddEmployee',
        component: AddEditEmployee,
        beforeEnter:requireAuth
    },
    {
        path:'/staff/edit-staff/:id',
        name: 'EditEmployee',
        component: AddEditEmployee,
        props:true,
        beforeEnter: requireAuth
    },
    // {
    //     path:'/komisi',
    //     name: 'CommissionView',
    //     component: CommissionView,
    //     beforeEnter: requireAuth
    // },
    {
        path:'/kalender',
        name: 'CalendarView',
        component: CalendarView_v2,
        beforeEnter: requireAuth
    },
    {
        path:'/penjualan',
        name: 'SalesView',
        component: SalesMainView,
        beforeEnter: requireAuth
    },
    {
        path:'/detail-invoice/:id',
        name: 'DetailInvoice',
        props:true,
        component: DetailInvoice,
        beforeEnter: requireAuth
    },
    {
        path:'/produk',
        name: 'ProductManagementView',
        component: ProductManagementView,
        beforeEnter: requireAuth
    },
    {
        path:'/produk/tambah-produk',
        name: 'AddProduct',
        component: AddEditProduct,
        beforeEnter: requireAuth
    },
    {
        path:'/produk/edit-produk/:id',
        name: 'EditProduct',
        props:true,
        component: AddEditProduct,
        beforeEnter: requireAuth
    },
    {
        path:'/kategori',
        component: CategoryView,
        beforeEnter: requireAuth
    },
    {
        path:'/appointment/add-new',
        name: 'AddAppoinment',
        component: AppointmentView,
        beforeEnter: requireAuth
    },
    {
        path:'/appointment/add-new/pelanggan/:props_customer_id',
        name: 'AddAppoinmentWithCustomer',
        props: true,
        component: AppointmentView,
        beforeEnter: requireAuth
    },
    {
        path:'/appointment/edit-transaksi/:transaction_id',
        name: 'EditAppointment',
        props: true,
        component: AppointmentView,
        beforeEnter: requireAuth
    },
    // {
    //     path:'/detail-agenda',
    //     name: 'DetailAgenda',
    //     component: DetailAgendaView,
    //     beforeEnter: requireAuth
    // },
    {
        path:'/checkout/transaksi/:id',
        name:'CheckoutView',
        props: true,
        component: CheckoutView,
        beforeEnter: requireAuth
    },
    {
        path:'/metode-pembayaran',
        name:'PaymentMethodView',
        component: PaymentMethodView,
        beforeEnter: requireAuth
    },
    {
        path:'/laporan',
        name:'ReportView',
        component: ReportView,
        beforeEnter: requireAuth
    },

// =========================================================================================================

    { path:'/profil', component: IndexProfileView, beforeEnter: requireAuth },

    // {
    //     path:'/dashboard/transaction/:id',
    //     name:'EditTransaction',
    //     props: true,
    //     component: DashboardView,
    //     beforeEnter: requireAuth
    // },
    // {
    //     path:'/order_list',
    //     component: OrderListView,
    //     beforeEnter: requireAuth
    // },
    // {
    //     path:'/absensi',
    //     component: AttendanceView,
    //     beforeEnter: requireAuth
    // },
    // {
    //     path:'/absensi/detail',
    //     component: AttendanceDetailView,
    //     beforeEnter: requireAuth
    // },

    // { path:'/pegawai/tambah-pegawai', name: 'AddEmployee', component: AddEditEmployee, beforeEnter: requireAuth },
    // { path:'/pegawai/edit-pegawai/:id', name: 'EditEmployee', component: AddEditEmployee, props:true, beforeEnter: requireAuth },
    // { path:'/pelanggan/edit-pelanggan/:id', name: 'EditCustomer', component: AddEditCustomer, props:true, beforeEnter: requireAuth },
    // { path:'/outlet', component: OutletView, beforeEnter: requireAuth },
    // { path:'/outlet/tambah-outlet', name:'AddOutlet', component: AddEditOutlet, beforeEnter: requireAuth },
    // { path:'/outlet/edit-outlet/:id', name:'EditOutlet', component: AddEditOutlet, props:true, beforeEnter: requireAuth },
    // { path:'/inventori', component: InventoryView, beforeEnter: requireAuth },
    // { path:'/inventori/tambah-inventori', name:'AddInventory', component: AddEditInventory, beforeEnter: requireAuth },
    // { path:'/inventori/edit-inventori/:id', name:'EditInventory', component: AddEditInventory, props:true,  beforeEnter: requireAuth },
    // { path:'/atribut', component: AttributeView, beforeEnter: requireAuth },
    // { path:'/atribut-list', component: AttributeListView, beforeEnter: requireAuth },
    
    // { path:'/kelola-produk', name: 'ProductManagementView', component: ProductManagementView, beforeEnter: requireAuth },
    // { path:'/kelola-produk/tambah-produk', name: 'AddProduct', component: AddEditProduct, beforeEnter: requireAuth },
    // { path:'/kelola-produk/edit-produk/:id', name: 'EditProduct', component: AddEditProduct, props:true, beforeEnter: requireAuth },
    // { path:'/riwayat-transaksi', component: TransactionView, beforeEnter: requireAuth },
    // { path:'/pengaturan', component: SettingView, beforeEnter: requireAuth },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;