<template>
   <div class="mb-6 flex items-center">
      <span><InputForm @change="fetchCommissions(1)" v-model="filter.start_date" input_class="w-40 me-2" type="date"/></span>
      <MinusIcon class="w-5 h-5"/>
      <span><InputForm @change="fetchCommissions(1)" v-model="filter.end_date" input_class="w-40 ms-2" type="date"/></span> 
      <span class="ms-3">
        <select v-model="selectedEmployee" @change="fetchCommissions(1)" class="block w-48 text-lg mt-1 flex-1 border border-gray-400 rounded-lg px-3 py-2 text-gray-500 placeholder:text-gray-300 placeholder:text-[12px] sm:text-sm sm:leading-6 focus:outline-none focus:ring focus:ring-gray-500">
          <option value="" selected>Semua staff</option>
          <option v-for="employee in allEmployees" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
        </select>
      </span>
    </div>
    <div class="overflow-x-scroll">
      <table class="table-auto w-full mb-6">
        <thead class="bg-gray-300">
          <tr>
            <th class="p-3 border text-center">No</th>
            <th class="p-3 border text-center">ID Transaksi</th>
            <th class="p-3 border text-left">Transaksi Selesai</th>
            <th class="p-3 border text-left">Staff</th>
            <th class="p-3 border text-left">Nama Produk</th>
            <th class="p-3 border text-left">Harga Asli Produk</th>
            <th class="p-3 border text-left flex justify-between items-center">
              Komisi
              <ExclamationCircleIcon
                @mouseover="handleMouseOver()"
                @mouseleave="handleMouseLeave()"
                class="h-4 w-4 text-gray-600"/>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="noDataFound">
            <td colspan="7" class="p-3 text-center italic text-gray-400">{{ notFoundMessage || 'Tidak ada data' }}</td>
          </tr>
          <tr v-else v-for="(commission, index) in allCommissions" :key="commission.id">
            <td class="p-3 border text-center">{{ index + 1 }}</td>
            <td class="p-3 border text-center">{{ commission.transactionCode || 'Loading...' }}</td>
            <td class="p-3 border">{{ formatTime(commission?.createdAt) }}</td>
            <td class="p-3 border capitalize">{{ commission?.user?.name }}</td>
            <td class="p-3 border">{{ commission.productName || 'Loading...' }}</td>
            <td class="p-3 border">{{ formatRupiah(commission.pos_transaction?.grand_total) }}</td>
            <td class="p-3 border">{{ formatRupiah(commission.commission) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
 </template>
 
 <script>
 import InputForm from '@/components/form/InputForm.vue';
 import { mapGetters, mapActions } from 'vuex';
 import { formatTime, formatRupiah } from '@/helpers/formating';
 import { MinusIcon } from '@heroicons/vue/24/solid';
 
 export default {
   name: "ReportCommission",
   components: {
     InputForm,
     MinusIcon
   },
   data() {
     return {
       selectedEmployee: '',
       filter: {
         start_date: new Date().toISOString().split('T')[0],
         end_date: new Date().toISOString().split('T')[0]
       }
     }
   },
   computed: {
     ...mapGetters('commissions', ['allCommissions', 'pagination']),
     ...mapGetters('employees', ['allEmployees'])
   },
   created() {
     this.fetchCommissions(this.pagination.page || 1);
     this.$store.dispatch('employees/fetchAllEmployees');
   },
   methods: {
     formatRupiah, formatTime,
     ...mapActions('commissions', ['fetchCommissions']),
     ...mapActions('transactions', ['fetchTransactionById']),
 
     handleMouseOver() {
       this.isHovered = true;
       this.animation_class = 'animate__fadeInDown';
     },
     handleMouseLeave() {
       this.isHovered = false;
       this.animation_class = 'animate__fadeOutUp';
     },
     async fetchCommissions(page = 1, limit = 50) {
       const params = {
         page,
         limit,
         user_id: this.selectedEmployee,
         start_date: this.filter.start_date,
         end_date: this.filter.end_date
       };
       try {
         await this.$store.dispatch('commissions/fetchCommissions', params);
 
         if (this.allCommissions.length === 0) {
           this.noDataFound = true;
         } else {
           this.noDataFound = false;
 
           // Fetch Transaction By "commission.pos_transaction_id"
           for (const commission of this.allCommissions) {
             const transaction = await this.fetchTransactionById(commission.pos_transaction_id);
             commission.transactionCode = transaction?.data?.transaction_code || 'N/A';
             commission.productName = transaction?.data?.service_items[0].product_detail.name || '~';
           }
         }
       }
       catch (error) {
         if (error.response && error.response.status == 404) {
           this.noDataFound = true; // No data found for the selected employee
           this.notFoundMessage = error.response.data.message
           this.$store.commit('commissions/setCommissions', []); // Clear the previous commissions data
         }
         else {
           // Handle other types of errors
           console.error('gagal menambil data:', error);
         }
       }
     },
   }
 }
 </script>