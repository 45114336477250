<template>
    <TopBar @toggleBlur="toggleBlur"/>
    <IndexView title="Beranda"/>
  <div
    id="overlay"
    class="fixed inset-0 bg-black bg-opacity-30 z-30"
    :class="{ 'hidden': !isBlur }"
  ></div>
  <section class="p-6">
    <!-- <div class="mb-6 flex justify-center">
      <div class="logo me-3 rounded-full w-36 h-36  flex justify-center items-center overflow-hidden">
        <img class="object-cover w-full h-auto" src="/images/jeju_logo.webp" alt="logo jeju">
      </div>
    </div> -->
    <h1 class="text-center font-light text-lg">
      Welcome to
      <span class="font-semibold text-primary-color-1 text-xl">Jeju POS</span>
    </h1>
    <div class="mt-3 mb-6 flex">
      <RouterLink to="/appointment/add-new" class="bg-primary-color-2 flex items-center rounded-lg text-blue-100 px-4 py-2 font-medium me-3 mb-3">
        <PlusCircleIcon class="h-5 w-5 me-1"/>
        Transaksi Baru
      </RouterLink>
      <RouterLink to="/kalender" class="bg-primary-color-2 flex items-center rounded-lg text-blue-100 px-4 py-2 font-medium me-3 mb-3">
        <WindowIcon class="h-5 w-5 me-1"/>
        Kalender
      </RouterLink>
    </div>
    <div class="">
      <h2 class="text-lg font-medium text-primary-color-1 mb-3">Transaksi Hari Ini</h2>
      <div class="flex flex-wrap">
        <span v-if="allTransactions.length == 0" class="text-gray-400 italic">~ Tidak ada Transaksi</span>
        <template v-else v-for="(trans) in allTransactions" :key="trans.id">
          <div class="card bg-white rounded-lg shadow-lg w-80 relative me-3 mb-3">
            <div class="card-body p-3">
              <!-- <span class="number text-3xl font-bold text-gray-300 absolute bottom-2 right-3">{{ index + 1 }}</span> -->
              <div class="flex justify-end">
                <span v-if="trans.payment_status == 2" class="text-xs bg-secondary-green-2 text-secondary-green-1 py-1 px-2 rounded-lg">Completed</span>
                <span v-if="trans.payment_status == 1" class="text-xs bg-secondary-red-2 text-secondary-red-1 py-1 px-2 rounded-lg">Pending</span>
              </div>
              <h3 class="text-secondary-red-1 font-bold mb-3">
                <template v-for="service in trans.service_items" :key="service.id">
                  {{ ' | ' + service?.product_detail?.name }}
                </template>
              </h3>
              <p class="text-sm">Customer : <span class="font-medium capitalize">{{ trans?.customer?.name }}</span></p>
              <p class="text-sm">Waktu : <span class="font-medium">{{ trans.appointment_hour }}</span></p>
              <p class="text-sm">Staff : 
                <template v-for="service in trans.service_items" :key="service.id">
                  <template v-for="staff in service.staff_list" :key="staff.id">
                    <span class="font-medium capitalize">{{ staff.employee_name + ', ' }}</span>
                  </template>
                </template>
              </p>
              <div class="flex justify-end mt-3">
                <button @click="redirectToCheckout(trans.id)" class="bg-primary-color-2 flex items-center justify-between rounded-lg w-26 text-blue-100 px-3 py-2 text-xs font-medium me-3 mb-3">
                  Checkout
                  <ChevronRightIcon class="h-4 w-4 ms-1"/>
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import TopBar from '@/components/TopBar.vue';
import IndexView from '@/views/IndexView.vue';
import { PlusCircleIcon, WindowIcon, ChevronRightIcon } from '@heroicons/vue/24/solid';
import { formatRupiah } from '@/helpers/formating';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DashboardView',
  components: {
    TopBar, IndexView, PlusCircleIcon, WindowIcon, ChevronRightIcon
  },
  data() {
    return {
      isBlur: false,

    }
  },
  async created() {
    this.fetchTransactions(this.pagination.page || 1);
  },
  computed: {
    ...mapGetters('transactions', ['allTransactions', 'pagination']),
  },
  methods: {
    formatRupiah,
    ...mapActions('transactions', ['fetchTransactions']),

    toggleBlur() {
      this.isBlur = !this.isBlur
    },
    async fetchTransactions(page = 1, limit = 20) {
      const params = {
        page,
        limit,
        appointment_date: new Date().toISOString().split('T')[0],
        customer_id: null,
        payment_status: null
      };
      await this.$store.dispatch('transactions/fetchTransactions', params);
    },
    redirectToCheckout(transaction_id) {
      this.$router.push({ name: 'CheckoutView', params: { id: transaction_id } });
    }
  }
}
</script>