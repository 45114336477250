<template>
  <div :class="{'animate__animated animate__fadeInUp': showModal, 'animate__animated animate__fadeOutDown': !showModal}" class="invoice-detail absolute bg-gray-100 rounded-lg right-[5%] max-w-[400px] shadow-lg border border-gray-300 overflow-hidden">
    <div class="invoice-header flex justify-between border-b bg-white border-gray-400">
      <div class="w-full py-2">
        <h5 class="text-center font-bold text-lg">Invoice</h5>
      </div>
      <PlusIcon @click="closeModal" class="h-6 w-6 rotate-45 cursor-pointer mt-1 me-1" />
    </div>
    <div class="invoice-body my-4 px-4">
      <div class="bg-white rounded-md p-4 text-sm max-h-60 overflow-scroll" ref="invoiceText">
        <p>Halo <strong>{{ transaction?.customer?.name }}</strong></p>
        <p>Terima kasih telah menggunakan layanan di <span class="font-bold">Jeju Nail, Eyelash & Salon Studio.</span> Berikut adalah detail order Anda :</p>
        <br>
        <p>No Invoice : <strong>{{ transaction?.invoice?.invoice_code }}</strong></p>
        <p>Nama : <strong>{{ transaction?.customer?.name }}</strong></p>
        <p>Tanggal Pengerjaan : <strong>{{ formatDate(transaction?.appointment_date) }}</strong></p>
        <p>Waktu Pengerjaan : <strong>{{ transaction?.appointment_hour }}</strong></p>
        <br>
        <p>Detail Layanan : </p>
        <ul>
          <li v-for="(service_item, index) in transaction?.service_items" :key="service_item.id">
            <p class="font-bold"> {{ index + 1 }}. {{ service_item?.product_detail?.name }} <span class="font-normal">({{ formatRupiah(service_item?.price_per_unit) }})</span></p>
          </li>
        </ul>
        <br>
        <p>Total yang harus dibayarkan : <span class="font-bold">{{ formatRupiah(transaction?.grand_total) }}</span></p>
        <br>
        <p>Pembayaran dapat dilakukan secara cash ataau melalui transfer ke re rekening :</p>
        <p>Bank : <span class="font-bold">BCA</span></p>
        <p>No Rekening : <span class="font-bold">1234567890</span></p>
        <p>Atas Nama : <span class="font-bold">Jeju</span></p>
        <br>
        <p>Jika ada pertanyaan, jangan ragu untuk menghubungi kami di nomor 081234567890</p>
      </div>
    </div>
    <div class="invoice-footer px-4 pb-4 flex justify-end">
      <button
        @click="copyText()"
        :class="buttonClass"
        class="rounded-lg px-3 flex items-center">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import { formatRupiah, formatDate } from '@/helpers/formating';
import { PlusIcon } from '@heroicons/vue/24/solid';

export default {
  name: "ModalInvoice",
  emits: ['closeModalInvoice'],
  props: {
    trx: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      showModal: true,
      buttonClass: 'bg-white border border-gray-300',
      buttonText: 'Copy',
      transaction: this.trx
    }
  },
  components: {
    PlusIcon
  },
  created() {
    console.log('invoice: ', this.transaction);
  },
  methods: {
    formatRupiah,formatDate,

    closeModal() {
      this.showModal = false;
      this.$emit('closeModalInvoice');
    },
    copyText() {
      const textToCopy = this.$refs.invoiceText.innerText;
      navigator.clipboard.writeText(textToCopy).then(() => {
        this.buttonText = 'Copied';
        this.buttonClass = 'bg-black text-white';
        
        setTimeout(() => {
          this.buttonText = 'Copy';
          this.buttonClass = 'bg-white border border-gray-300';
        }, 2000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  }
}
</script>