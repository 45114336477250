<template>
  <div class="mb-6">
    <InputForm id="filter-by-date" @change="fetchTransactions()" v-model="filter.appointment_date" type="date"/>
  </div>

  <!-- Calendar Header -->
  <!-- <div class="hidden grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8 grid-cols-9 grid-cols-10"></div> -->
  <div class="grid w-full" :class="'grid-cols-' + (parseInt(allEmployees.length) + 1)">
    <span class="py-3 text-center flex justify-center items-center">
      <ClockIcon class="h-6 w-6"/>
    </span>
    <template v-for="employee in allEmployees" :key="employee.id">
      <span class="text-center col-auto uppercase text-primary-color-2 py-3">
        {{ employee.name }}
      </span>
    </template>
  </div>

  <!-- Calendar Body -->
  <div class="grid w-full" :class="'grid-cols-' + (parseInt(allEmployees.length) + 1)">
    <div class="py-3 text-center border-r col-auto">
      <div v-for="time in times" :key="time.id" class="border-b h-12 flex items-center justify-center">{{ time.name }}</div>
    </div>
    <template v-for="employee in allEmployees" :key="employee.id">
      <div class="text-center border-r col-auto py-3">
        <div v-for="time in times" :key="time.id" class="p-3 border-b h-12 overflow-hidden text-primary">
          <!-- <div v-if="getEmployeeTransaction(employee.id, time.name)" class="bg-blue-100 rounded-xl card py-1 px-3 hover:absolute">
            <p class="text-left font-bold">{{ getEmployeeTransaction(employee.id, time.name)?.service_items[0]?.product_detail?.name }}</p>
            <p class="mb-1 text-left">{{ getEmployeeTransaction(employee.id, time.name)?.appointment_hour }} | {{ formatDate(getEmployeeTransaction(employee.id, time.name)?.appointment_date) }}</p>
            <p class=" text-left capitalize font-light">{{ getEmployeeTransaction(employee.id, time.name)?.customer?.name }}</p>
          </div>
          <div v-else class="text-gray-400 italic">~</div> -->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InputForm from '@/components/form/InputForm.vue';
import { ClockIcon } from '@heroicons/vue/24/solid';
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/helpers/formating';

export default {
  name: "CalendarTabView1",
  components: {
    InputForm,
    ClockIcon
  },
  data() {
    return {
      times: [
        { id: 1, name: '07:00' },
        { id: 2, name: '08:00' },
        { id: 3, name: '09:00' },
        { id: 4, name: '10:00' },
        { id: 5, name: '11:00' },
        { id: 6, name: '12:00' },
        { id: 7, name: '13:00' },
        { id: 8, name: '14:00' },
        { id: 9, name: '15:00' },
        { id: 10, name: '16:00' },
        { id: 11, name: '17:00' },
      ],

      filter: {
        // appointment_date: new Date().toISOString().split('T')[0]
        appointment_date: "2024-09-09"
      }
    }
  },
  computed: {
    ...mapGetters('transactions', ['allTransactions', 'pagination']),
    ...mapGetters('employees', ['allEmployees']),
  },
  created() {
    this.fetchTransactions();
    this.$store.dispatch('employees/fetchAllEmployees');
  },
  methods: {
    formatDate,
    ...mapActions('transactions', ['fetchTransactions']),

    async fetchTransactions() {
      const params = {
        appointment_date: this.filter.appointment_date || null,
      };
      const response = await this.$store.dispatch('transactions/fetchTransactions', params);
      console.log('transactions:', response);
    },

    // getEmployeeTransaction(employeeId, time) {
    //   // Set the date for filtering (simulasi hari ini di 2024-09-04)
    //   // const today = "2024-09-04";
    //   // this.filter.today = today;
    //   const today = this.filter.today;

    //   // Parse the hour from the `time` argument (e.g., "09:00" -> "09")
    //   const timeHour = time.split(':')[0];

    //   // Filter transactions by today's date, matching employee, and matching hour
    //   return this.allTransactions.find(trans => {
    //     const transDate = trans.appointment_date ? trans.appointment_date.split('T')[0] : null;
        
    //     // Extract the hour part from the transaction's appointment time
    //     const transHour = trans.appointment_hour ? trans.appointment_hour.split(':')[0] : null;

    //     // Check if the date matches and if the hour matches
    //     if (transDate == today && transHour == timeHour) {
    //       // Check if the employee is part of the transaction's service staff
    //       return trans.service_items.some(service =>
    //         service.staff_list.some(staff => staff.id === employeeId)
    //       );
    //     }

    //     return false; // No match found
    //   });
    // },
  }
}
</script>