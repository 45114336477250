<template>
  <label class="font-medium" :class="label_class">{{ label }}</label>
  <input
      :id="id"
      :type="type"
      :value="modelValue"
      :class="input_class"
      :placeholder="placeholder"
      :disabled="is_disabled"
      :maxlength="2"
      @input="validateInput($event)"
      class="block text-lg mt-1 flex-1 border border-gray-400 rounded-lg px-3 py-1 text-gray-500 placeholder:text-gray-400 placeholder:text-[12px] sm:text-sm sm:leading-6 focus:outline-none focus:ring focus:ring-gray-500"
  >
</template>

<script>
export default {
  name: 'InputTime',
  props: {
      label: String,
      label_class: String,
      id: String,
      type: String,
      name: String,
      placeholder: String,
      input_class: String,
      modelValue: String,
      is_disabled: Boolean
  },
  emits: ['update:modelValue'],
  methods: {
    validateInput(event) {
      const value = event.target.value;

      if (this.type === 'number') {
        if (this.name === 'hours' && (value < 0 || value > 23)) {
          event.target.value = this.modelValue;
        }
        if (this.name === 'minutes' && (value < 0 || value > 59)) {
          event.target.value = this.modelValue;
        }
      }
      this.$emit('update:modelValue', event.target.value);
    }
  }
}
</script>
