import axios from 'axios';

const state = {
  commissions: [],
  pagination: {
    page: 1,
    perPage: 10,
    totalItems: 0,
    previousPageLink: null,
    nextPageLink: null
  }
}

const actions = {
  async fetchCommissions({ commit }, { page = 1, limit = 10, user_id =  '', start_date = '', end_date = '' }) {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/user-commissions`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: { page, limit, user_id, start_date, end_date }
      });
      const data = await response.data;
      commit('setCommissions', data);
      commit('setPagination', data.pagination);
    }
    catch (error) {
      if (error.response && error.response.status == 404) {
        commit('setCommissions', []); // No data found
      } else {
        console.error('Failed to fetch commissions:', error);
      }
    }
  },
  async fetchAllCommissions({ commit }) {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/user-commissions`, {
          headers: {
              'Authorization': `Bearer ${token}`
          },
      });
      const data = await response.data;
      commit('setCommissions', data);
    }
    catch (error) {
      console.error('Failed to fetch commissions:', error);
    }
},
  async fetchCommissionById(_, id) {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/user-commissions/${id}`, {
        headers: {
        'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    }
    catch (error) {
      console.error('Failed to fetch commission:', error);
      throw error;
    }
  },
}

const mutations = {
  setCommissions(state, commissions) {
    state.commissions = commissions.data;
  },

  setPagination(state, pagination) {
    state.pagination.page = pagination.page || 1;
    state.pagination.perPage = pagination.perPage || 10;
    state.pagination.totalItems = pagination.totalItems || 0;

    state.pagination.previousPageLink = pagination.previousPageLink ? pagination.previousPageLink.replace(/\/api\/v1\/customers\?/, '') : null;
    state.pagination.nextPageLink = pagination.nextPageLink ? pagination.nextPageLink.replace(/\/api\/v1\/customers\?/, '') : null;
  },
}

const getters = {
  allCommissions: (state) => state.commissions,
  pagination: (state) => state.pagination
}

export default {
  namespaced: true,
  state, mutations, actions, getters
}