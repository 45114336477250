<template>
   <section class="w-screen h-screen absolute flex justify-center items-center">
      <div class="modal absolute block w-3/4 bg-white shadow-lg rounded-lg border-t-4 border-primary-color-2 z-50"
      :class="{
      'animate__animated animate__zoomIn': showModal,
      'animate__animated animate__zoomOut': !showModal,
      }">
         <div class="modal-body p-6">
            <div class="product overflow-hidden w-full flex justify-between border rounded-lg">
               <div class="flex">
                  <div class="w-[100px] md:w-[80px] overflow-hidden">
                     <img class="object-cover w-full h-full" src="/images/jeju_logo.webp" alt="">
                  </div>
                  <div class="py-2 px-4">
                     <h5 class="text-secondary-red-1 font-bold text-lg mb-1">{{ product_selected.name }} <span class="text-sm text-gray-800 font-normal">( {{ product.duration }} Menit )</span></h5>
                     <div class="flex justify-between text-gray-400">
                        <span v-if="parseInt(product.discount) !== 0">
                           <p class="line-through text-sm">{{ formatRupiah(product.sale_price) }}</p>
                           <h4 class="font-bold text-black">{{ formatRupiah(calculateDiscount(product.sale_price, product.discount)) }}</h4>
                        </span>
                        <h4 v-if="parseInt(product.discount) == 0" class="font-bold text-black">{{ formatRupiah(product.sale_price) }}</h4>
                     </div>
                  </div>
               </div>
               <div class="category_name p-3">
                  <h5 class="font-bold text-primary-color-2 text-lg">{{ product?.Category?.name }}</h5>
               </div>
            </div>
            <div class="mt-3">
               <h5 class="font-bold">Deskripsi :</h5>
               <p class="font-light">{{ product.desc }}</p>
            </div>
            <div class="date-time my-6 flex">
               <!-- <div class="w-1/2 me-6">
                  <InputForm v-model="service_item.order_date" label="Tanggal" type="date" input_class="w-full"/>
               </div> -->
               <div class=" me-6">
                  <label class="font-medium">Waktu</label>

                  <!-- Vue The Mask -->
                  <!-- <the-mask :mask="['##:##']"/> -->

                  <div class="flex items-center">
                     <InputTime 
                        type="number"
                        name="minutes" 
                        input_class="w-[65px] text-center" 
                        v-model="start_time.hours" 
                        placeholder="HH"/>
                     <span class="mx-1">:</span>
                     <InputTime 
                        type="number"
                        name="minutes"
                        input_class="w-[65px] text-center" 
                        v-model="start_time.minutes" 
                        placeholder="MM"/>

                     <!-- <MinusIcon class="w-5 h-5 mx-2"/>

                     <InputTime 
                        type="number"
                        name="minutes" 
                        input_class="w-[65px] text-center" 
                        v-model="end_time.hours" 
                        placeholder="HH"/>
                     <span class="mx-1">:</span>
                     <InputTime 
                        type="number"
                        name="minutes" 
                        input_class="w-[65px] text-center" 
                        v-model="end_time.minutes" 
                        placeholder="MM"/> -->
                  </div>
               </div>
               <div>
                  <label class="mb-1 font-medium block">Staff</label>
                  <select v-model="selected_staff_id" @change="selectStaff()" class="bg-white w-full rounded-lg border mb-3 py-1 px-2">
                     <option value="">Pilih staff</option>
                     <option v-for="employee in availableEmployees" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
                  </select>
                  <div class="w-full flex flex-wrap">
                     <span v-if="selected_staff.length <= 0" class="text-gray-400 italic">
                        ~ Belum ada staff dipilih
                     </span>
                     <span v-for="(staff, index) in selected_staff" :key="index" class="staff bg-secondary-blue-2 text-secondary-blue-1 px-3 mb-1 py-1 rounded-md m-1">
                        {{ staff.name }}
                        <button class="text-secondary-red-1 ms-2" @click="removeStaff(staff)">
                           <TrashIcon class="h-4 w-4"/>
                        </button>
                     </span>
                  </div>
               </div>
            </div>
            <!-- <div class="mb-3">
               <label class="mb-1 font-medium block">Staff</label>
               <select v-model="selected_staff_id" @change="selectStaff()" class="bg-white w-full rounded-lg border mb-3 py-1 px-2">
                  <option value="">Pilih staff</option>
                  <option v-for="employee in availableEmployees" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
               </select>
               <div class="w-full flex flex-wrap">
                  <span v-if="selected_staff.length <= 0" class="text-gray-400 italic">
                     ~ Belum ada staff dipilih
                  </span>
                  <span v-for="(staff, index) in selected_staff" :key="index" class="staff bg-secondary-blue-2 text-secondary-blue-1 px-3 mb-1 py-1 rounded-md m-1">
                     {{ staff.name }}
                     <button class="text-secondary-red-1 ms-2" @click="removeStaff(staff)">
                        <TrashIcon class="h-4 w-4"/>
                     </button>
                  </span>
               </div>
            </div> -->
            <div>
               <TextArea v-model="service_item.service_note" label="Catatan" rows="3" />
            </div>
         </div>
         <div class="modal-footer flex justify-end p-6 border-t border-gray-400">
            <SubmitButton button_name="Tutup" button_class="bg-gray-300 rounded-lg me-3" @click="closeModal"/>
            <SubmitButton @click="save()" button_name="Simpan" button_class="bg-primary-color-2 text-white rounded-lg"/>
         </div>
      </div>
   </section>
 </template>
<script>
import SubmitButton from '../form/SubmitButton.vue';
// import InputForm from '../form/InputForm.vue';
import InputTime from '../form/InputTime.vue';
import TextArea from '../form/TextArea.vue';
// import { TheMask } from "vue-the-mask";
import { calculateDiscount } from '@/helpers/calculation';
import { formatRupiah } from '@/helpers/formating';
import { TrashIcon } from '@heroicons/vue/24/solid';
import { mapGetters } from 'vuex';

export default {
   name: 'ModalProduct',
   emits: ['closeModalProduct', 'serviceItem'],
   props: {
      product: {
         type: Object,
         default: null,
      }
   },
   components: {
      // TheMask,
      SubmitButton, InputTime, TextArea,
      TrashIcon
   },
   data() {
      return {
         showModal: true,
         service_item: {
            transaction_type: 2,
            product_id: null,
            quantity: 1,
            price_per_unit: 0,
            discount: 0,
            subtotal: 0,
            service_note: '',
            order_date: '',
            start_service_time: '',
            end_service_time: '',
            staffs: [],
         },
         staff: {
            user_id: null,
            employee_name: '',
            working_status: 1
         },

         product_selected: {
            name: this.product.name,
            sale_price: this.product.sale_price
         },
         start_time: {
            hours: '',
            minutes: ''
         },
         // end_time: {
         //    hours: '',
         //    minutes: ''
         // },

         selected_staff: [],
         selected_staff_id: '',
      };
   },
   methods: {
      formatRupiah, calculateDiscount,

      closeModal() {
         this.showModal = false;
         this.$emit('closeModalProduct');
      },
      selectStaff() {
         const selected = this.allEmployees.find(employee => employee.id === this.selected_staff_id);
         if (selected) {
            this.selected_staff.push(selected);
         }

         const staffData = {
            pos_service_item_id: null,
            user_id: selected.id,
            employee_name: selected.name,
            working_status: 1
         }
         // Masukkan data staff yang dipilih ke "service_item.staffs"
         this.service_item.staffs.push(staffData);

         this.selected_staff_id = '';

         console.log("service_item: ", this.service_item);
      },
      
      removeStaff(staff) {
         this.selected_staff = this.selected_staff.filter(selected => selected.id !== staff.id);
         
         // Hapus staff dari service_item.staffs
         this.service_item.staffs = this.service_item.staffs.filter(item => item.user_id !== staff.id);
      },
      formatTime(hours, minutes) {
         // Jika jam atau menit kosong, isi dengan '00'
         let hh = hours ? String(hours).padStart(2, '0') : '00';
         let mm = minutes ? String(minutes).padStart(2, '0') : '00';
         return `${hh}:${mm}`;
      },
      save() {
         this.service_item.transaction_id = null;
         this.service_item.product_id = this.product.id;
         this.service_item.price_per_unit = this.product.sale_price;
         this.service_item.discount = this.product.discount;
         this.service_item.subtotal = calculateDiscount(this.product.sale_price, this.product.discount);
         this.service_item.start_service_time = this.formatTime(this.start_time.hours, this.start_time.minutes);
         // this.service_item.end_service_time = this.formatTime(this.end_time.hours, this.end_time.minutes);
         console.log("final service item: ", this.service_item);

         this.$emit('serviceItem', this.service_item);
         this.closeModal();
      }
   },
   computed: {
      ...mapGetters('employees', ['allEmployees']),
      availableEmployees() {
         return this.allEmployees.filter(employee => !this.selected_staff.find(selected => selected.id === employee.id));
      }
   },
   created() {
      this.$store.dispatch('employees/fetchAllEmployees');
      console.log("this product ", this.product);
   },
}
</script>