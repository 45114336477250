<template>
    <section class="mb-[70px]">
        <TopBar @toggleBlur="toggleBlur" :pageTitle="title"/>
        <SideBar ref="sidebar" @toggleBlur="toggleBlur"/>
        <div
            id="overlay"
            class="fixed inset-0 bg-black bg-opacity-30 z-40"
            :class="{ 'hidden': !isBlur }"
            @click="hideSidebar()"
        ></div>
    </section>
</template>

<script>
import SideBar from '@/components/SideBar.vue';
import TopBar from '@/components/TopBar.vue';

export default {
    name: 'IndexView',
    props: {
      title: String,
   },
    components: { SideBar, TopBar },
    data() {
        return {
            isBlur: false,
            pageTitle: '',
        }
    },
    methods: {
        toggleBlur() {
            this.isBlur = !this.isBlur
        },
        hideSidebar() {
            this.$refs.sidebar.closeSidebar();
        }
    }
}
</script>

<style>
#overlay {
    backdrop-filter: blur(5px);
}
</style>